import React from "react";
import "./DemoItem.css";
import {Button, Typography} from "@mui/material";
import {goldColor, darkGoldColor} from "../routes/home";
import notFoundImage from "../Images/undraw_Page_not_found_re_e9o6.png";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

const DemoItem = ({
    title = '',
    subTitle = '',
    text = '',
    image = notFoundImage,
    imageAlt = 'demo-image-alt',
    buttonText = 'Check it out',
    handleButtonClick = () => {},
    isInactive = false,
    inactiveText = 'IN PROGRESS'
}) => {
    return (
        <div style={{opacity: isInactive ? 0.6 : 1}} key={'container-' + imageAlt}>
            <div className={"demo-item-container"} key={'demo-container-' + imageAlt}>
                {
                    isInactive ?
                        <span
                            style={{
                                position: "absolute",
                                fontSize: "2.5rem",
                                backgroundColor: "#111",
                                borderRadius: 10,
                                padding: "4px 8px",
                                opacity: 0.9
                            }}
                            key={'inactive-span-' + imageAlt}
                        >
                            {inactiveText}
                        </span>
                        : null
                }
                <div className={"demo-item-image-container"} key={'image-container-' + imageAlt}>
                    <img src={image} alt={imageAlt} className={"demo-item-image"} key={'image-' + imageAlt}/>
                </div>
                <div className={"demo-item-text-container"} key={'text-container-' + imageAlt}>
                    <div key={'title-subtitle-container-' + imageAlt}>
                        <Typography variant={"h2"} style={{fontSize: "1.5rem", color: goldColor}} key={'title-' + imageAlt}>
                            {title}
                        </Typography>
                        <Typography variant={"body1"} style={{fontSize: '1.3rem'}} key={'subTitle-' + imageAlt}>
                            {subTitle}
                        </Typography>
                    </div>
                    <Typography variant={"body1"} style={{fontSize: '1.3rem'}} key={'text-' + imageAlt}>
                        {text}
                    </Typography>
                    <Button
                        variant={'contained'}
                        endIcon={<OpenInNewOutlinedIcon/>}
                        disabled={isInactive}
                        onClick={handleButtonClick}
                        style={{backgroundColor: darkGoldColor}}
                        key={'button-' + imageAlt}
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default DemoItem;
