import { useEffect } from "react";
import "./tools.css";
import LightForecastDemo from "../Images/lightForecastDemo.png"
import PointingPokerImage from "../Images/pointing-poker-demo.jpg"
import FocusDemo from "../Images/focus-demo.jpg";
import RSSDemo from "../Images/RSS-reader-demo.png";
import WebsitesDemo from "../Images/websites-demo.jpg";
import DemoItem from "../Components/DemoItem";
import {useNavigate} from "react-router-dom";

const Tools = () => {
    const navigate = useNavigate();
    const handleOnClick = () => navigate('/');
    const handlePointingPokerClick = () => navigate('planning-poker');
    const handleFocusClick = () => navigate('focus');
    const handleRSSClick = () => navigate('err-rss-reader');
    const handleWebsitesClick = () => navigate('websites');

    useEffect(() => {
        document.title = "Tools - Säälik Media";
     }, []);

    return ([
        <span
            className={"link-here"}
            style={{fontSize: "1.5rem", padding: 20, position: "absolute"}}
            onClick={handleOnClick}
            key={"home-span"}
        >
            <b>Säälik Media</b>
        </span>,
        <div className={"tools-container"} key={"tools-container"}>
            <div className={"tools-wrapper"} key={"tools-wrapper"}>
                <DemoItem
                    title={'Various websites'}
                    subTitle={'Wordpress, React, HTML'}
                    text={'Different solutions based on clients needs, budget and other variables.'}
                    image={WebsitesDemo}
                    imageAlt={"light-forecast-app-demo"}
                    handleButtonClick={handleWebsitesClick}
                    isInactive={false}
                />
                <DemoItem
                    title={'Focus (MVP)'}
                    subTitle={'React'}
                    text={'Input your TODO list and get a categorized and sorted output based on the Eisenhower matrix.'}
                    image={FocusDemo}
                    handleButtonClick={handleFocusClick}
                    imageAlt={"focus-app-demo"}
                />
                <DemoItem
                    title={'Planning Poker'}
                    subTitle={'React'}
                    text={'Also known as Scrum Poker and Pointing Poker is a gamified method to estimate the effort for different projects and development tasks with the team.'}
                    image={PointingPokerImage}
                    handleButtonClick={handlePointingPokerClick}
                    imageAlt={"pointing-poker-demo"}
                />
                <DemoItem
                    title={'RSS news reader'}
                    subTitle={'React'}
                    text={'Simple RSS feed reader. Using err.ee/rss endpoint. User can choose between scroller and swiper mode.'}
                    image={RSSDemo}
                    imageAlt={"light-forecast-app-demo"}
                    handleButtonClick={handleRSSClick}
                    isInactive={false}
                />
                <DemoItem
                    title={'Light Forecast'}
                    subTitle={'React Native'}
                    text={'React Native app to get an estimate for the amount of light at a given time and place.'}
                    image={LightForecastDemo}
                    imageAlt={"light-forecast-app-demo"}
                    isInactive={true}
                />
            </div>
        </div>
    ]);
}

export default Tools;