import React, { useState, useEffect } from "react";
import "./ErrRssReader.css";
import { Skeleton, Card, CardContent, Typography, CardActions, Button, IconButton } from "@mui/material";
import ViewAgenda from "@mui/icons-material/ViewAgenda";
import ViewCarousel from "@mui/icons-material/ViewCarousel";
import { ArrowDownward } from "@mui/icons-material";
import { ArrowUpward } from "@mui/icons-material";
import { useSwipeable } from "react-swipeable";

const parser = new DOMParser();
const swipeConfig = { delta: 40,trackMouse: true };

function xml2json(xml) {
    try {
        const objects = [];
        const xmlDoc = parser.parseFromString(xml, "text/xml");
        const items = xmlDoc.getElementsByTagName("item");

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const title = item.getElementsByTagName("title")[0]?.textContent;
            const link = item.getElementsByTagName("link")[0]?.textContent;
            const description = item.getElementsByTagName("description")[0]?.textContent;
            const date = item.getElementsByTagName("pubDate")[0]?.textContent;
            const category = item.getElementsByTagName("category")[0]?.textContent;
            const imageUrl = item.getElementsByTagName("media:thumbnail")[0]?.getAttribute("url");
        
            if (!!description) {
                objects.push({
                    title: title,
                    link: link,
                    description: description,
                    category: category,
                    date: date,
                    imageUrl: imageUrl
                });
            }
        }
        return objects;
        
    } catch (e) {
        console.log(e.message);
    }
  }

const ErrRssReader = () => {
    const [news, setNews] = useState(null);
    const [readingMode, setReadingMode] = useState("swiper");
    const [activeItem, setActiveItem] = useState(0);

    useEffect(() => {
        if (document.title !== "ERR RSS reader") {
            document.title = "ERR RSS reader";
        }
        if (!news?.length) {
            fetch("https://www.err.ee/rss").then((res) => res?.text().then(resText => setNews(xml2json(resText)))).catch((err) => console.log(err))
        }
    }, [news?.length]);

    const handleNext = () => {
        if (activeItem === news?.length - 1) {
            setActiveItem(0);
        } else {
            setActiveItem(activeItem + 1);
        }
    };

    const handlePrev = () => {
        if (activeItem === 0) {
            setActiveItem(news?.length - 1);
        } else {
            setActiveItem(activeItem - 1);
        }
    };

    const handlers = useSwipeable({
        onSwiped: () => handlePrev(),
        onSwipedDown: () => handleNext(),
        ...swipeConfig
    });

    return <div className={"rss-reader"}>
        {
            readingMode !== "swiper" &&
                <>
                    <Typography variant="h1" component="div" fontSize={"1.5rem"} color={"gray"} style={{padding: "10px 20px"}}>Simple RSS news reader (err.ee)</Typography>
                    <div style={{display: "flex", flexDirection: "row", columnGap: 20, justifyContent: "space-around", alignItems: "center"}}>
                        <Typography variant="subtitle1" component="div" fontSize={"1rem"} color={"gray"}>Reading mode:</Typography>
                        <Button className={"variation-button"} variant={"outlined"} onClick={() => setReadingMode("cards")}>Scroller <ViewAgenda /></Button>
                        <Button className={"variation-button"} variant={"outlined"} onClick={() => setReadingMode("swiper")}>Swiper <ViewCarousel /></Button>
                    </div>
                </>
        }
        {
            readingMode === "cards" ?
            (
                !!news && news?.length ?
                news.map((item, index) =>
                    <Card sx={{ minWidth: 275 }} key={`card-${index}`}>
                        <CardContent>
                            <div className={"card-item"}>
                                <Typography variant="h5" component="div">{item.title}</Typography>
                                <div style={{display: "flex", flexDirection: "row", columnGap: 10}}>
                                    <div style={{display: "flex", background: `url(${news?.[index]?.imageUrl}) no-repeat center`, backgroundSize: "auto 100%", height: 100, width: 100, minHeight: 100, minWidth: 100}}/>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">{item.description}</Typography>
                                </div>
                            </div>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => window.open(item.link, "_blank")} variant="outlined">Loe lähemalt</Button>
                        </CardActions>
                    </Card>
                ) :
                <Card sx={{ minWidth: 275 }} key={'card-empty'}>
                    <CardContent className={"card-item"}>
                        <Skeleton width={200} height={40}></Skeleton>
                        <Skeleton width={250} height={26}></Skeleton>
                        <Skeleton width={200} height={26}></Skeleton>
                    </CardContent>
                    <CardActions>
                        <Skeleton width={80} height={60} style={{marginLeft: 10}}></Skeleton>
                    </CardActions>
                </Card>
            ) :
            (
                !!news && news?.length && news?.[activeItem]?.title &&
                    <div
                        className={"swiper-container"}
                        style={{background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${news?.[activeItem]?.imageUrl}) no-repeat center`, backgroundSize: "auto 100%"}}
                        key={`swiper-slide-${activeItem}`}
                        {...handlers}
                    >
                        <div className={"top-menu"} style={{display: "flex"}}>
                            <div className={"variation-button-wrapper"}>
                                <Button
                                    className={"variation-button"}
                                    variant={"outlined"}
                                    onClick={() => setReadingMode("cards")}
                                >
                                    Scroller
                                    <ViewAgenda />
                                </Button>
                                <Button
                                    className={"variation-button"}
                                    variant={"outlined"}
                                    onClick={() => setReadingMode("swiper")}
                                >
                                    Swiper
                                    <ViewCarousel style={{transform: "rotate(90deg)"}} />
                                </Button>
                            </div>
                            <IconButton 
                                style={{width: 40, alignSelf: "center"}} 
                                onClick={handlePrev}
                            >
                                <ArrowUpward />
                            </IconButton>
                        </div>
                        <div className={"news-container"} style={{display: "flex"}}>
                            <Typography
                                className={"common-typography"}
                                style={{backgroundColor: "#e1e1e190", fontWeight: 600}}
                                variant="h5"
                                component="div"
                            >{news?.[activeItem]?.title}</Typography>
                            <Typography
                                className={"common-typography"}
                                style={{backgroundColor: "#1b1b1bd6", width: "80%", fontSize: "1.2rem"}}
                                color="text.secondary"
                            >{news?.[activeItem]?.description}</Typography>
                            <Button size="small" onClick={() => window.open(news?.[activeItem]?.link, "_blank")} variant="outlined">Loe lähemalt</Button>
                        </div>
                        <div style={{width: "100%"}}>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", paddingLeft: 10, rowGap: 4}}>
                                <Typography variant="subtitle1" className={"subtitle-text"}>{news?.[activeItem]?.category}</Typography>
                                <Typography variant="subtitle2" className={"subtitle-text"}>{news?.[activeItem]?.date}</Typography>
                            </div>
                            <div className={"bottom-menu"} style={{display: "flex"}}>
                                <IconButton onClick={handleNext}><ArrowDownward /></IconButton>
                            </div>
                        </div>
                    </div>
            )
        }
    </div>
};

export default ErrRssReader;
