import {useState, useEffect} from "react";
import { Typography, Button, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { goldColor, linkGoldColor } from "../home";
import EditDialog from "./EditDialog";
import CreateTaskDialog from "./CreateTaskDialog";
import DeleteTaskConfirmationDialog from "./DeleteTaskConfirmationDialog";
import CompleteTaskConfirmationDialog from "./CompleteTaskConfirmationDialog";
import CommentsDialog from "./CommentsDialog";
import { db } from "../../index";
import {ref, get, remove, set, update, onValue} from "firebase/database";

const TaskRow = ({task, handleMenuClick = () => {}, handleRowClick = () => {}}) => {
    const lastComment = Object.values(task?.comments || {})[Object.values(task?.comments || {})?.length - 1]?.text || "";
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: 10,
                    alignItems: "center",
                    borderBottom: "1px solid #484848",
                    padding: "5px 0 5px 0"
                }}
            >
                <Typography variant="body2" style={{minWidth: 30}}>{task.importance}</Typography>
                <Typography variant="body2" style={{minWidth: 30, paddingRight: 30}}>{task.urgency}</Typography>
                <div
                    style={{minWidth: 550, textAlign: "left", cursor: "pointer"}}
                    onClick={() => {
                        if (task?.id || task?.id === 0) {
                            handleRowClick(task.id);
                        }
                    }}
                >
                    <Typography variant="body1" >{task.title}</Typography>
                    {
                        lastComment ?
                            <Typography
                                variant="body2"
                                style={{minWidth: 30, paddingLeft: 10, textAlign: "left", fontSize: 12, opacity: 0.5}}
                                >
                                    <InsertCommentOutlinedIcon fontSize="12" style={{paddingRight: 5}}/>
                                    {lastComment}
                                </Typography>
                            : null
                    }
                </div>
                <IconButton onClick={(e) => handleMenuClick(e, task.id)}><MoreVertIcon fontSize="small"/></IconButton>
            </div>
        </>
    );
}

const EmptyTaskRow = () => {
    return <TaskRow task={{title: "-", importance: "-", urgency: "-"}} />;
}

const RowMenu = ({anchorEl, open, handleClose}) => {
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose('close')}
            MenuListProps={{'aria-labelledby': 'basic-button'}}
        >
            <MenuItem onClick={() => handleClose('comment')}><InsertCommentOutlinedIcon style={{paddingRight: 10}} fontSize="small"/>Tracklog</MenuItem>
            <MenuItem onClick={() => handleClose('edit')}><EditOutlinedIcon style={{paddingRight: 10}} fontSize="small"/> Edit task</MenuItem>
            <MenuItem onClick={() => handleClose('complete')}><CheckCircleOutlineOutlinedIcon style={{paddingRight: 10}} fontSize="small"/> Complete task</MenuItem>
            <MenuItem onClick={() => handleClose('delete')}><DeleteOutlineOutlinedIcon style={{paddingRight: 10}} fontSize="small"/> Delete task</MenuItem>
        </Menu>
    )
}

const Dashboard = ({
    email = '',
    uid = '',
    executeTasks = [],
    scheduleTasks = [],
    delegateTasks = [],
    eliminateTasks = [],
    allTasks = [],
    setGuidedTourDialogOpen = () => {},
    taskCount,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const rowMenuOpen = Boolean(anchorEl);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [createTaskDialogOpen, setCreateTaskDialogOpen] = useState(false);
    const [deleteTaskDialogOpen, setDeleteTaskDialogOpen] = useState(false);
    const [completeTaskDialogOpen, setCompleteTaskDialogOpen] = useState(false);
    const [commentTaskDialogOpen, setCommentTaskDialogOpen] = useState(false);
    const [activeTask, setActiveTask] = useState(null);
    const [editedTask, setEditedtask] = useState(null);
    const [newComment, setNewComment] = useState("");
    const [remoteComments, setRemoteComments] = useState({});

    const dummyTask = {id: taskCount || 0, title: "", urgency: 5, importance: 5, status: "active"};

    // Get tasks with clean up
    useEffect(() => {
        if (activeTask?.id || activeTask?.id === 0) {
            return onValue(ref(db, `focus/${uid}/tasks/${activeTask?.id}/comments`), (snapshot) => {
                const comments = snapshot?.val() || {};
                if (!!comments && Object.values(comments)?.length > 0) {
                    setRemoteComments(comments);
                } else {
                    setRemoteComments({});
                }
            });
        }
    }, [uid, activeTask?.id]);

    const handleUpdateSingleTaskDb = async () => {
        await update(ref(db, `focus/${uid}/tasks/${activeTask.id}`), {
            title: editedTask.title,
            urgency: editedTask.urgency,
            importance: editedTask.importance,
            updatedTs: Date.now(),
        }).catch(() => {
            // Do nothing in production
        });
    };

    const handleCompleteSingleTaskDb = async () => {
        // Get completed tasks
        await get(ref(db, `focus/${uid}/completedTasks`)).then((snapshot) => {
            const completedTasks = snapshot?.val() || {};
            const completedTaskArray = Object.values(completedTasks);

            // If there are more than 25 completed tasks, delete the oldest one
            if (completedTaskArray.length >= 25) {
                const sortedTasks = completedTaskArray.toSorted((a, b) => a.updatedTs - b.updatedTs);
                const oldestTaskId = sortedTasks[0].id;
                remove(ref(db, 'focus/' + uid + '/completedTasks/' + (oldestTaskId))
                ).catch(() => {
                    // Do nothing in production
                });
            }

            // Add the completed task to the completed tasks list
            set(ref(db, `focus/${uid}/completedTasks/${activeTask.id}`), {
                ...activeTask,
                status: "completed",
                updatedTs: Date.now(),
            }).then(() => {
                handleDeleteTaskDb();
            }).catch(() => {
                // Do nothing in production
            });
        }).catch(() => {
            // Do nothing in production
        });
    };

    const handleCreateTaskDb = async () => {
        await set(ref(db, `focus/${uid}/tasks/${taskCount}`), {
            ...editedTask,
            createdTs: Date.now(),
            updatedTs: Date.now(),
        }).then(() => {
            handleUpdateTaskCountDb();
        }).catch(() => {
            // Do nothing in production
        });
    };

    const handleUpdateTaskCountDb = async () => {
        await update(ref(db, 'focus/' + uid), {
            taskCount: taskCount + 1,
        }).catch(() => {
            // Do nothing in production
        });
    };

    const handleDeleteTaskDb = async () => {
        await remove(ref(db, `focus/${uid}/tasks/${activeTask.id}`)
        ).catch(() => {
            // Do nothing in production
        });
    };

    const handleAddCommentDb = async () => {
        const commentsLength = Object.values(remoteComments)?.length || 0;
        await update(ref(db, `focus/${uid}/tasks/${activeTask.id}/comments`), {
            [commentsLength]: {text: newComment, createdDt: Date.now()},
        }).then(() => {
            setNewComment("");
        }).catch(() => {
            // Do nothing in production
        });
    };

    const handleCommentDialogSave = () => {
        handleAddCommentDb();
    }

    const handleCreateTaskDialogOpen = () => {
        setEditedtask(dummyTask);
        setCreateTaskDialogOpen(true);
    };

    const handleEditDialogSave = () => {
        setEditDialogOpen(false);
        handleUpdateSingleTaskDb();
    };

    const handleDeleteTask = () => {
        setDeleteTaskDialogOpen(false);
        handleDeleteTaskDb();
    };

    const handleCompleteTask = () => {
        setCompleteTaskDialogOpen(false);
        handleCompleteSingleTaskDb();
    };

    const handleCreateTask = () => {
        setCreateTaskDialogOpen(false);
        handleCreateTaskDb();
    };

    const handleMenuClick = (event, taskId) => {
        setAnchorEl(event.currentTarget);
        const selectedTask = allTasks?.[taskId];
        if (selectedTask) {
            setActiveTask(selectedTask);
            setEditedtask(selectedTask);
        }
    };

    const handleRowClick = (taskId) => {
        const selectedTask = allTasks?.[taskId];
        if (selectedTask) {
            setActiveTask(selectedTask);
            setEditedtask(selectedTask);
            setCommentTaskDialogOpen(true);
        }
    };

    const handleMenuClose = (action) => {
        setAnchorEl(null);

        if (action === 'edit') {
            setEditDialogOpen(true);
        }

        if (action === 'delete') {
            setDeleteTaskDialogOpen(true);
        }

        if (action === 'complete') {
            setCompleteTaskDialogOpen(true);
        }

        if (action === 'comment') {
            setCommentTaskDialogOpen(true);
        }
    };
  
    return (
        <div style={{
            width: 700,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: 60,
        }}>
            {
                !allTasks || allTasks?.length < 1 ?
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: "inherit", paddingTop: 30, rowGap: 20}}>
                        <Typography variant="h5">It appears you have no tasks yet.</Typography>
                        <Button
                            variant={"contained"}
                            size={"large"}
                            onClick={() => setGuidedTourDialogOpen(true)}
                        >
                            Start creating focus
                        </Button>
                    </div>
                    :
                    <>
                        <div style={{display: "flex", width: "inherit", flexDirection: "row", columnGap: 30, justifyContent: "space-between"}}>
                            <Typography variant="h4">Your action plan</Typography>
                            <Button
                                variant={"contained"}
                                size={"small"}
                                onClick={handleCreateTaskDialogOpen}
                            >
                                Add more tasks +
                            </Button>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", columnGap: 10, paddingTop: 20}}>
                            <Typography variant="subtitle1" style={{fontSize: 8, alignSelf: "flex-end"}}>Importance</Typography>
                            <Typography variant="subtitle1" style={{fontSize: 8, alignSelf: "flex-end"}}>Urgency</Typography>
                            <Typography variant="h5" style={{paddingLeft: 20, color: goldColor}}>First priorities</Typography>
                        </div>
                        {
                            executeTasks?.length > 0 ?
                                executeTasks.map(task => <TaskRow task={task} handleMenuClick={handleMenuClick} handleRowClick={handleRowClick}/>)
                                : <EmptyTaskRow />
                        }
                        <Typography variant="h5" style={{marginLeft: 110, marginTop: 20, color: goldColor}}>Plan</Typography>
                        {
                            scheduleTasks?.length > 0 ?
                                scheduleTasks.map(task => <TaskRow task={task} handleMenuClick={handleMenuClick} handleRowClick={handleRowClick}/>)
                                : <EmptyTaskRow />
                        }
                        <Typography variant="h5" style={{marginLeft: 110, marginTop: 20, color: goldColor}}>Delegate</Typography>
                        {
                            delegateTasks?.length > 0 ?
                                delegateTasks.map(task => <TaskRow task={task} handleMenuClick={handleMenuClick} handleRowClick={handleRowClick}/>)
                                : <EmptyTaskRow />
                        }
                        <Typography variant="h5" style={{marginLeft: 110, marginTop: 20, color: goldColor}}>Postpone/Eliminate</Typography>
                        {
                            eliminateTasks?.length > 0 ?
                                eliminateTasks.map(task => <TaskRow task={task} handleMenuClick={handleMenuClick} handleRowClick={handleRowClick}/>)
                                : <EmptyTaskRow />
                        }
                        <div style={{paddingTop: 100, paddingBottom: 50, width: "inherit"}}>
                            <Typography variant={"h1"} style={{fontSize: "2rem"}} color={goldColor}>Give us feedback</Typography>
                            <a
                                href="https://forms.gle/uqUgKshUfz6FQqxS8"
                                target="_blank"
                                rel="noreferrer"
                                style={{color: linkGoldColor}}
                            >https://forms.gle/uqUgKshUfz6FQqxS8</a>
                        </div>
                    </>
            }
            <RowMenu anchorEl={anchorEl} open={rowMenuOpen} handleClose={handleMenuClose} />
            <EditDialog open={editDialogOpen} handleClose={() => setEditDialogOpen(false)} handleSave={handleEditDialogSave} task={editedTask} setEditedtask={setEditedtask} />
            <CreateTaskDialog open={createTaskDialogOpen} handleClose={() => setCreateTaskDialogOpen(false)} handleCreate={handleCreateTask} task={editedTask} setEditedtask={setEditedtask} />
            <DeleteTaskConfirmationDialog open={deleteTaskDialogOpen} handleClose={() => setDeleteTaskDialogOpen(false)} handleDelete={handleDeleteTask} />
            <CompleteTaskConfirmationDialog open={completeTaskDialogOpen} handleClose={() => setCompleteTaskDialogOpen(false)} handleComplete={handleCompleteTask} />
            <CommentsDialog open={commentTaskDialogOpen} handleClose={() => setCommentTaskDialogOpen(false)} handleSave={handleCommentDialogSave} task={activeTask} newComment={newComment} setNewComment={setNewComment} remoteComments={remoteComments}/>
        </div>
    );
};

export default Dashboard;