import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home, {darkGoldColor, goldColor} from "./routes/home";
import Software from "./routes/software";
import Tools from "./routes/tools";
import ErrorPage from "./routes/error";
import PlanningPoker from "./routes/planningPoker";
import FocusApp from "./routes/focused";
import ErrRssReader from "./routes/ErrRssReader/ErrRssReader";
import {createTheme, ThemeProvider} from "@mui/material";
import PlanningPokerSession from "./routes/planningPokerSession";
import {getDatabase} from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import {initializeApp} from "firebase/app";
import { getAuth } from "firebase/auth";
import Websites from './routes/websites/websites';

const firebaseConfig = {
    apiKey: "AIzaSyA4fgLnjVNOMk8yEQ6aMt4Zp4Swg0-oiec",
    authDomain: "saalik-media.firebaseapp.com",
    databaseURL: "https://saalik-media-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "saalik-media",
    storageBucket: "saalik-media.appspot.com",
    messagingSenderId: "55790051922",
    appId: "1:55790051922:web:c18c1276ea3223c357f58b",
    measurementId: "G-6FL844L4MH"
  };

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);

const theme = createTheme({
    palette: {
        primary: {
            main: darkGoldColor,
        },
        secondary: {
            main: goldColor,
        },
        mode: "dark"
    },
});

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/software",
        element: <Software />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/tools",
        element: <Tools />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/tools/planning-poker",
        element: <PlanningPoker />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/tools/planning-poker/:sessionId",
        element: <PlanningPokerSession />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/tools/focus",
        element: <FocusApp />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/tools/err-rss-reader",
        element: <ErrRssReader />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/tools/websites",
        element: <Websites />,
        errorElement: <ErrorPage />,
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
