import {useState, useEffect} from "react";
import './focused.css';
import {Button, CircularProgress, IconButton, Typography} from "@mui/material";
import focusImage from "../Images/undraw_dev_focus_re_6iwt.svg";
import { goldColor } from "./home";
import { auth, db } from "../index";
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";
import GoogleIcon from '@mui/icons-material/Google';
import LogoutIcon from '@mui/icons-material/Logout';
import Dashboard from "./focus/dashboard";
import {set, ref, update, onValue} from "firebase/database";
import GuidedTourDialog from "./focus/GuidedTourDialog";
import SuccessLogDialog from "./focus/SuccessLogDialog";

const provider = new GoogleAuthProvider();

const FocusApp = () => {
    const defaultTasks = {
        0: {id: 0, title: "", urgency: 5, importance: 5, createdTs: Date.now(), updatedTs: Date.now(), status: "active"},
        1: {id: 1, title: "", urgency: 5, importance: 5, createdTs: Date.now(), updatedTs: Date.now(), status: "active"},
        2: {id: 2, title: "", urgency: 5, importance: 5, createdTs: Date.now(), updatedTs: Date.now(), status: "active"},
    };
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [tasks, setTasks] = useState(defaultTasks);
    const [remoteTasks, setRemoteTasks] = useState(null);
    const [uid, setUid] = useState(null);
    const [email, setEmail] = useState(null);
    const [guidedTourDialogOpen, setGuidedTourDialogOpen] = useState(false);
    const [successLogDialogOpen, setSuccessLogDialogOpen] = useState(false);
    const [taskCount, setTaskCount] = useState(0);
    const [completedTasks, setCompletedTasks] = useState({});

    useEffect(() => {
        document.title = "Focus";
        setLoading(true);

        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUid(user.uid);
                setEmail(user.email);
                setLoading(false);
            } else {
                setUid(null);
                setEmail(null);
                setLoading(false);
            }
        });
     }, []);

    // Get tasks with clean up
    useEffect(() => {
        return onValue(ref(db, `focus/${uid}/tasks`), (snapshot) => {
            const tasks = snapshot?.val() || {};
            if (!!tasks && Object.values(tasks)?.length > 0) {
                setTasks(tasks);
                setRemoteTasks(tasks);
            } else {
                setActiveStep(1);
            }
        });
    }, [uid, activeStep]);

    // Get completed tasks with clean up
    useEffect(() => {
        return onValue(ref(db, `focus/${uid}/completedTasks`), (snapshot) => {
            const tasks = snapshot?.val() || {};
            if (!!tasks && Object.values(tasks)?.length > 0) {
                setCompletedTasks(tasks);
            }
        });
    }, [uid]);

    // Get taskCount with clean up
    useEffect(() => {
        return onValue(ref(db, `focus/${uid}/taskCount`), (snapshot) => {
            const taskCount = snapshot?.val();
            if (taskCount !== undefined && taskCount !== null) {
                setTaskCount(taskCount);
            }
        });
    }, [uid]);

    const handleNextClick = () => {
        if (activeStep === 4) { // TODO: Check this. Maybe better to just use setGuidedTourDialogOpen(false)
            setGuidedTourDialogOpen(false);
            setActiveStep(1);
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleAddTasksToDb = async () => {
        await set(ref(db, 'focus/' + uid), { // use update() instead?
            uid: uid,
            tasks: tasks,
            createdTs: Date.now(),
            updatedTs: Date.now(),
            taskCount: tasks.length,
        }).then(() => {
            handleNextClick();
        }).catch(() => {
            // Do nothing in production
        });
    };

    const handleUpdateTasksDb = async () => {
        await update(ref(db, 'focus/' + uid), {
            tasks: tasks,
            updatedTs: Date.now(),
            taskCount: tasks.length,
        }).then(() => {
            handleNextClick();
        }).catch(() => {
            // Do nothing in production
        });
    };

    const handleLogInClick = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                const user = result.user;
                if (user && user?.uid && user.email) {
                    setUid(user.uid);
                    setEmail(user.email);
                    setActiveStep(1);
                }
            }).catch(() => {
                // Do nothing in production
            });
    }

    const handleLogOutClick = () => {
        auth.signOut().then(() => {
            // Sign-out successful.
            setUid(null);
            setEmail(null);
        }).catch(() => {
            // Do nothing in production
        });
    }

    const executeTasks = (Object.values(tasks)?.filter(task => task.urgency > 5 && task.importance > 5) || [])
        .sort((a, b) => b.importance * b.urgency - a.importance * a.urgency);
    const scheduleTasks = (Object.values(tasks)?.filter(task => task.urgency <= 5 && task.importance > 5) || [])
        .sort((a, b) => b.importance * b.urgency - a.importance * a.urgency);
    const delegateTasks = (Object.values(tasks)?.filter(task => task.urgency > 5 && task.importance <= 5) || [])
        .sort((a, b) => b.importance * b.urgency - a.importance * a.urgency);
    const eliminateTasks = (Object.values(tasks)?.filter(task => task.urgency <= 5 && task.importance <= 5) || [])
        .sort((a, b) => b.importance * b.urgency - a.importance * a.urgency);

    if (loading) {
        return <div style={{height: "100vh", width: "100vw", display: "flex", placeContent: "center", alignItems: "center"}}>
                <CircularProgress />
            </div>
    }

    return (
        <div className={"focused-container"}>
            {
                uid && email ? 
                    <>
                        <div style={{
                                position: "absolute",
                                top: 0,
                                right: 20,
                                display: "flex",
                                columnGap: 10,
                                alignItems: "center",
                            }}
                        >
                            <Button
                                variant={"outlined"}
                                size={"small"}
                                onClick={() => Object.values(completedTasks)?.length > 0 && setSuccessLogDialogOpen(true)}
                                style={{marginRight: 50}}
                            >
                                Success log
                            </Button>
                            <Typography variant={"body"} color={goldColor}>{email}</Typography>
                            <IconButton onClick={handleLogOutClick}><LogoutIcon fontSize="small"/></IconButton>
                        </div>
                        <Dashboard
                            uid={uid}
                            email={email}
                            executeTasks={executeTasks}
                            scheduleTasks={scheduleTasks}
                            delegateTasks={delegateTasks}
                            eliminateTasks={eliminateTasks}
                            allTasks={remoteTasks}
                            setGuidedTourDialogOpen={setGuidedTourDialogOpen}
                            taskCount={taskCount}
                        />
                    </>
                    :
                    <>
                        <div>
                            <Typography variant={"h1"} style={{fontSize: "4rem", paddingBottom: 16}}>Focus</Typography>
                            <img src={focusImage} className={'focus-image'} alt={'focus-app'}/>
                        </div>
                        <div className={"start-session-container"}>
                            <Button
                                variant={"contained"}
                                size={"large"}
                                onClick={handleLogInClick}
                            >
                                Log In&nbsp;<GoogleIcon fontSize="small"/>
                            </Button>
                        </div>
                    </>
            }
            <GuidedTourDialog
                open={guidedTourDialogOpen}
                handleClose={() => setGuidedTourDialogOpen(false)}
                tasks={tasks}
                setTasks={setTasks}
                activeStep={activeStep}
                remoteTasks={remoteTasks}
                handleAddTasksToDb={handleAddTasksToDb}
                handleUpdateTasksDb={handleUpdateTasksDb}
                handleNextClick={handleNextClick}
            />
            <SuccessLogDialog open={successLogDialogOpen} handleClose={() => setSuccessLogDialogOpen(false)} tasks={completedTasks} />
        </div>
    )
}

export default FocusApp;
