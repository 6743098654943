import { useEffect } from "react";
import "./websites.css";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import YariloDemo from "./../../Images/yarilo-demo.jpg";
import TanSaalikDemo from "./../../Images/tansaalik-demo.jpg";
import TondisuruDemo from "./../../Images/tondisuru.mp4";
import OutlastDemo from "./../../Images/outlast-demo.jpg";
import {useNavigate} from "react-router-dom";

export function ImgMediaCard({mediaUrl, mediaType = "img", mediaAlt, title, content, liveDemoUrl}) {
    const buttonText = !liveDemoUrl ? "Website not available anymore" : "Check live demo";
    return (
        <Card sx={{ width: 345 }}>
            {
                mediaType === "video" ?
                <video controls autoPlay muted height={190}>
                    <source src={mediaUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                :
                <CardMedia
                    alt={mediaAlt}
                    height="190"
                    image={mediaUrl}
                    component={mediaType}
                />
            }
            <CardContent sx={{ minHeight: 150}}>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {content}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    disabled={!liveDemoUrl}
                    onClick={() => liveDemoUrl ? window.open(liveDemoUrl, '_blank') : {}}
                >{buttonText}</Button>
            </CardActions>
        </Card>
    );
}

const Websites = () => {
    const navigate = useNavigate();
    const handleOnClick = () => navigate('/');

    useEffect(() => {
        document.title = "Websites - Säälik Media";
     }, []);

    return (
        <>
            <span
                className={"link-here"}
                style={{fontSize: "1.5rem", padding: 20, position: "absolute"}}
                onClick={handleOnClick}
            >
                <b>Säälik Media</b>
            </span>
            <div className={"websites-container"}>
                <div className={"websites-wrapper"}>
                    <ImgMediaCard
                        mediaUrl={TanSaalikDemo}
                        mediaAlt={"screenshot of tansaalik.com website"}
                        title={"Tan Säälik Photography"}
                        content={<>Design from me.<br/> Built in Wordpress.<br/> Fully custom content via Bricks builder.<br/>No premade themes.</>}
                        liveDemoUrl={"https://tansaalik.com"}
                    />
                    <ImgMediaCard
                        mediaUrl={OutlastDemo}
                        mediaAlt={"screenshot of outlast.ee website"}
                        title={"Outlast"}
                        content={<>Design from the client.<br/> Built in React.<br/> In continuous development based on traffic and market research.</>}
                        liveDemoUrl={"https://outlast.ee"}
                    />
                    <ImgMediaCard
                        mediaUrl={YariloDemo}
                        mediaAlt={"screenshot of yarilo.ee website"}
                        title={"Yarilo OÜ"}
                        content={<>Design from the client.<br/> Built in Wordpress.<br/> In continuous development with small increments.</>}
                        liveDemoUrl={"https://yarilo.ee"}
                    />
                    <ImgMediaCard
                        mediaUrl={TondisuruDemo}
                        mediaType={"video"}
                        mediaAlt={"video of tondisuru.ee website"}
                        title={"Tondisuru"}
                        content={<>Design from me.<br/> Built in Wordpress.<br/> The site is unfortunately not available anymore, so I have provided a short video as a demo.</>}
                    />
                </div>
            </div>
        </>
    );
}

export default Websites;