import {useRef, useEffect} from "react";
import "./software.css";
import {Typography} from "@mui/material";
import logo from "../Images/logo-1-gold.png";
import profileImage from "../Images/profile_large_cropped.png";
import starshipLogo from "../Images/Starship.png";
import bondoraLogo from "../Images/bondora.png";
import thkLogo from "../Images/thk.png";
import taltechLogo from "../Images/taltech.jpeg";
import {goldColor} from "./home";

const Software = () => {
    const aboutMeRef = useRef(null);
    const experienceRef = useRef(null);
    const topRef = useRef(null);

    useEffect(() => {
        document.title = "Software - Säälik Media";
     }, []);

    const executeAboutMeScroll = () => aboutMeRef.current.scrollIntoView({behavior: "smooth", block: "start"});
    const executeExperienceScroll = () => experienceRef.current.scrollIntoView({behavior: "smooth", block: "start"});
    const executeTopScroll = () => topRef.current.scrollIntoView({behavior: "smooth", block: "start"});

    return ([
        <div className={"Software-App"} ref={topRef}>
            <div className={"Software-left"}>
                <header className={"Software-header"}>
                    <Typography variant={"h1"} style={{fontSize: "3rem"}}>Software development</Typography>
                </header>
                <div className={"Software-grid"}>
                    <div className={"Software-section"}>
                        <div className={"Software-section-wrapper"}>
                            <Typography variant={"h2"} style={{maxWidth: "90%", fontSize: "2rem", color: goldColor}}>
                                Demos & Tools
                            </Typography>
                            <Typography variant={"h5"}>
                                Majority of my work is for private companies and can not be shown.
                            </Typography>
                            <Typography variant={"h5"}>
                                However my personal hobby projects can be seen <a href="/tools"><b>here</b></a>
                            </Typography>
                        </div>
                    </div>
                    <div className={"Software-section"}>
                        <div className={"Software-section-wrapper"}>
                            <Typography variant={"h2"} style={{maxWidth: "90%", fontSize: "2rem", color: goldColor, width: "100%"}}>
                                Previous experience
                            </Typography>
                            <Typography variant={"h5"}>
                                Scroll down or <span onClick={executeAboutMeScroll} className={"link-here"}><b>click here</b></span>
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"Software-right"}>
                <img src={logo} alt={"saalik-media-logo"}/>
            </div>
        </div>,
        <div className={"About-me-container"} ref={aboutMeRef}>
            <div className={'profileWrapper'}>
                <div className={'profileDetails'}>
                    <Typography variant={"h4"} style={{fontSize: "calc(1.5rem + 1vw)"}}>Tan Säälik</Typography>
                    <Typography variant={"h5"} style={{fontSize: "calc(1rem + 1vw)"}}>Software Engineer with Product Manager experience</Typography>
                    <Typography variant={"h7"} className={'paddingTop'}>Resilient - Curious - Team Player</Typography>
                    <Typography variant={"h7"} className={'paddingTop'}>Node.js - React.js - React Native - GraphQL - Wordpress</Typography>
                    <span className={"link-here-scroll"} onClick={executeExperienceScroll}><b>↓</b></span>
                </div>
                <div>
                    <img src={profileImage} alt={'profile'} className={'profileImage'}/>
                </div>
            </div>
        </div>,
        <div className={"Experience-container"} ref={experienceRef}>
            <div className={'experienceWrapper'}>
                <div className={'experienceDetails'}>
                    <Typography variant={"h2"} style={{fontSize: "2rem"}}>Experience</Typography>
                    <table>
                        <tbody>
                            <tr>
                                <td><img src={bondoraLogo} className={"experience-logo"} alt={"bondora-logo"}/></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Bondora</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>August 2023 - Current</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Software engineer</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Full time employee</Typography></td>
                            </tr>
                            <tr>
                                <td><img src={bondoraLogo} className={"experience-logo"} alt={"bondora-logo"}/></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Bondora</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Sept 2022 - March 2023</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Software engineer</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Contractor - Project based</Typography></td>
                            </tr>
                            <tr>
                                <td><img src={starshipLogo} className={"experience-logo"} alt={"starship-logo"}/></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Starship Technologies</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>May 2020 - August 2023</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Software engineer</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Full time employee</Typography></td>
                            </tr>
                            <tr>
                                <td><img src={starshipLogo} className={"experience-logo"} alt={"starship-logo"}/></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Starship Technologies</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Jun 2016 - May 2020</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Product Manager</Typography></td>
                                <td><Typography variant={"h6"} className={'paddingTop'}>Full time employee</Typography></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={'experienceDetails'}>
                    <Typography variant={"h2"} style={{fontSize: "2rem"}}>Education</Typography>
                    <table>
                        <tbody>
                        <tr>
                            <td><img src={thkLogo} className={"experience-logo"} alt={"thk-logo"}/></td>
                            <td><Typography variant={"h6"} className={'paddingTop'}>THK</Typography></td>
                            <td><Typography variant={"h6"} className={'paddingTop'}>Jun 2017 - May 2019</Typography></td>
                            <td><Typography variant={"h6"} className={'paddingTop'}>Information Technology</Typography></td>
                        </tr>
                        <tr>
                            <td><img src={taltechLogo} className={"experience-logo"} alt={"taltech-logo"}/></td>
                            <td><Typography variant={"h6"} className={'paddingTop'}>Tal Tech</Typography></td>
                            <td><Typography variant={"h6"} className={'paddingTop'}>Jun 2016 - Jan 2018</Typography></td>
                            <td><Typography variant={"h6"} className={'paddingTop'}>Batchelor of Information Technology</Typography></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={"back-to-top-wrapper"}>
                    <span className={"link-here"} style={{fontSize: "1.5rem"}} onClick={executeTopScroll}><b>Back to top ↑</b></span>
                </div>
            </div>
        </div>
    ]);
}

export default Software;