import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'

const SuccessLogDialog = ({open, handleClose, tasks}) => {
    if (!tasks || !Object.values(tasks)?.length) return null;

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
            <DialogTitle>Your 25 latest completed tasks</DialogTitle>
            <DialogContent>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 20}}>
                    <Table sx={{ minWidth: 450 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" width={30}>Importance</TableCell>
                                <TableCell align="center" width={30}>Urgency</TableCell>
                                <TableCell>Title</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Object.values(tasks)?.map((task) => (
                                    <TableRow key={task.id}>
                                        <TableCell align="center" width={30}>{task.importance}</TableCell>
                                        <TableCell align="center" width={30}>{task.urgency}</TableCell>
                                        <TableCell>{task.title}</TableCell>
                                    </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default SuccessLogDialog;