import {useState, useEffect} from "react";
import './planningPoker.css';
import {Button, TextField, Typography} from "@mui/material";
import planningPokerImage from "../Images/undraw_scrum_board_re_wk7v.svg";
import {set, ref} from "firebase/database";
import {db} from "../index";
import {useNavigate} from "react-router-dom";
import {getFromLocalStorage, setToLocalStorage} from "../utils/localStorage";
import {findSession, findUser, startNewVotingRound, pushUserToExistingSessionIfNotAlreadyThere} from "../utils/database";

const short = require("short-uuid");

const PlanningPoker = () => {
    const [existingSessionString, setExistingSessionString] = useState(null);
    const [userIdLocal, setUserIdLocal] = useState(getFromLocalStorage('userId'));
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Planning Poker";
     }, []);

    const checkIfUserExistsAndCreateIfNot = async () => {
        let userId = userIdLocal;
        if (!userIdLocal) {
            const uniqueUserId = short.generate();
            setToLocalStorage('userId', uniqueUserId);
            userId = uniqueUserId;
            setUserIdLocal(userId);
        }
        const foundUser = await findUser(userId);

        if (!foundUser?.userId) {
            await set(ref(db, 'users/' + userId), {
                userId: userId,
                username: 'Anonymous',
                role: 'player',
            });
        }
    };

    const handleJoinSessionClick = async () => {
        const sessionInDb = await findSession(existingSessionString);
        if (existingSessionString && sessionInDb?.sessionId) {
            navigate(existingSessionString);
            await checkIfUserExistsAndCreateIfNot();
            await pushUserToExistingSessionIfNotAlreadyThere(existingSessionString, getFromLocalStorage('userId'));
        }
    }

    const handleStartSessionClick = async () => {
        let sessionAuthorUserId = userIdLocal;
        const uniqueSessionId = short.generate();

        if (!userIdLocal) {
            const uniqueUserId = short.generate();
            setToLocalStorage('userId', uniqueUserId);
            sessionAuthorUserId = uniqueUserId;
            setUserIdLocal(sessionAuthorUserId);
        }
        const foundUser = await findUser(sessionAuthorUserId);

        if (!foundUser?.userId) {
            await set(ref(db, 'users/' + sessionAuthorUserId), {
                userId: sessionAuthorUserId,
                username: 'Anonymous',
                role: 'player',
            });
        }

        await set(ref(db, 'sessions/' + uniqueSessionId), {
            sessionId: uniqueSessionId,
            name: 'Session with no name',
            sessionAuthorUserId: sessionAuthorUserId,
            createdTs: Date.now(),
        });

        await pushUserToExistingSessionIfNotAlreadyThere(uniqueSessionId, sessionAuthorUserId);
        await startNewVotingRound(uniqueSessionId, Date.now());
        navigate(uniqueSessionId);
    }

    return (
        <div className={"poker-container"}>
            <div className={"poker-wrapper"}>
                <div>
                    <Typography variant={"h1"} style={{fontSize: "4rem", paddingBottom: 16}}>Planning Poker</Typography>
                    <img src={planningPokerImage} className={'poker-image'} alt={'planning-poker'}/>
                </div>
                <div className={"start-session-container"}>
                    <Button
                        variant={"contained"}
                        size={"large"}
                        onClick={handleStartSessionClick}
                    >
                        Start a new session
                    </Button>
                    <Typography variant={"body1"}>Or alternatively</Typography>
                    <div className={"horizontal-flex"}>
                        <TextField
                            variant={"outlined"}
                            color={"primary"}
                            size={"small"}
                            placeholder={"Enter a session ID"}
                            style={{marginRight: 20}}
                            onChange={(e) => setExistingSessionString(e.currentTarget.value)}
                            autoComplete={"off"}
                        >
                            {existingSessionString}
                        </TextField>
                        <Button
                            variant={"outlined"}
                            color={"secondary"}
                            onClick={handleJoinSessionClick}
                        >
                            Join existing session
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlanningPoker;
