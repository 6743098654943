import { useEffect } from "react";
import './home.css';
import {Typography} from "@mui/material";
import logo from "../Images/logo-1-gold.png";
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

export const goldColor = "#D4AF37";
export const linkGoldColor = "#d1c9b0";
export const darkGoldColor = "#a2872b";

const Home = () => {

    useEffect(() => {
        document.title = "Säälik Media";
     }, []);

    return (
        <div className={"App"}>
            <div className={"App-left"}>
                <header className={"App-header"}>
                    <Typography variant={"h1"} style={{fontSize: "3rem"}}>Säälik Media</Typography>
                </header>
                <Typography variant={"h2"} style={{maxWidth: "80%", fontSize: "2rem"}}>
                    Providing software development and photography service to <b style={{color: goldColor}}>realize your ideas.</b>
                </Typography>
                <div className={"App-grid"}>
                    <a
                        href={"https://tansaalik.com"}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        className={"App-card"}
                    >
                        <Typography variant={"h5"} className={"App-card-title"}>
                            <CameraAltOutlinedIcon fontSize={"large"} style={{paddingRight: 8}}/>
                            Photography
                        </Typography>
                        <Typography variant={"h6"} style={{paddingTop: 14, fontSize: "calc(1rem + 0.5vw)"}}>
                            Events, portrait, boudoir, aerial photography and more. Including videography.
                        </Typography>
                    </a>
                    <a href="/software" className={"App-card"}>
                        <Typography variant={"h5"} className={"App-card-title"}>
                            <TerminalOutlinedIcon fontSize={"large"} style={{paddingRight: 8}}/>
                            Software development
                        </Typography>
                        <Typography variant={"h6"} style={{paddingTop: 14, fontSize: "calc(1rem + 0.5vw)"}}>
                            Frontend, backend, web and mobile. With most experience in web development.
                        </Typography>
                    </a>
                </div>
            </div>
            <div className={"App-right"}>
                <img src={logo} alt={"saalik-media-logo"}/>
            </div>
        </div>
    );
}
export default Home;
