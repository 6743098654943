import {ref, child, get, update, push, set} from "firebase/database";
import {db} from "../index";

export const findUser = async (userId) => {
    const dbRef = ref(db);
    return await get(child(dbRef, `users/${userId}`)).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val();
        } else {
            return null;
        }
    }).catch(err => {
        console.error(err);
        return null;
    });
};

export const findSession = async (sessionId) => {
    const dbRef = ref(db);
    return await get(child(dbRef, `sessions/${sessionId}`)).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val();
        } else {
            return null;
        }
    }).catch(err => {
        console.error(err);
        return null;
    });
};

export const updateUserNameAndRole = async (userId, userName, userRole, sessionId) => {
    return await update(ref(db, `users/${userId}`), {
        username: userName,
        role: userRole,
        lastActive: Date.now(),
    }).then(res => {
        updateUserUpdatedInSessions(userId, sessionId);
    }).catch(err => {
        console.error(err);
        return null;
    });
};

export const updateUserLastActive = async (userId, ts) => {
    return await update(ref(db, `users/${userId}`), {
        lastActive: ts,
    }).catch(err => {
        console.error(err);
        return null;
    });
};

export const updateUserUpdatedInSessions = async (userId, sessionId) => {
    return await update(ref(db, `sessions/${sessionId}/users/${userId}`), {
        updatedTs: Date.now(),
    }).catch(err => {
        console.error(err);
        return null;
    });
};

export const updateActiveVotingRoundId = async (sessionId, votingRoundId) => {
    return await update(ref(db, `sessions/${sessionId}`), {
        activeRoundId: votingRoundId,
    }).catch(err => {
        console.error(err);
        return null;
    });
};

export const startNewVotingRound = async (sessionId, ts) => {
    const newVotingRoundId = push(ref(db, `sessions/${sessionId}/votingRounds`), {
            startTs: ts,
            showVotes: false,
    }).key;
    if (newVotingRoundId) {
        await updateActiveVotingRoundId(sessionId, newVotingRoundId);
    }
};

export const showVotingRoundVotes = async (sessionId, votingRoundId) => {
    return await update(ref(db, `sessions/${sessionId}/votingRounds/${votingRoundId}`), {
        showVotes: true,
    }).catch(err => {
        console.error(err);
        return null;
    });
};

export const pushUserToExistingSessionIfNotAlreadyThere = async (sessionId, userId) => {
    const dbRef = ref(db);
    const userAlreadyInSession = await get(child(dbRef, `sessions/${sessionId}/users`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                return Object.values(data)?.some(it => it.userId === userId) || false;
            } else {
                return false;
            }})
        .catch(err => {
            console.error(err);
            return false;
        });

    if (!userAlreadyInSession) {
        return await set(ref(db, `sessions/${sessionId}/users/${userId}`), {
            userId: userId,
            updatedTs: Date.now()
        }).catch(err => {
            console.error(err);
            return null;
        });
    }
};
