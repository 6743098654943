import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
    TextField
} from "@mui/material";
import {setToLocalStorage} from "../utils/localStorage";

const ProfileDialog = ({
    open,
    handleClose,
    forcedEdit = true,
    userNameLocal= '',
    userRoleLocal= 'player'
}) => {
    const [name, setName] = useState(userNameLocal);
    const [role, setRole] = useState(userRoleLocal);

    const closeDialog = () => {
        setToLocalStorage('username', name);
        setToLocalStorage('userrole', role);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={() => !forcedEdit ? handleClose() : null}>
            <DialogTitle>Update your profile</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin={"dense"}
                    id={"name"}
                    label={"Username / Nickname"}
                    fullWidth
                    variant={"outlined"}
                    autoComplete={"off"}
                    onChange={(e) => setName(e.currentTarget.value)}
                    value={name}
                />
                <FormControl>
                    <FormLabel id="radio-buttons-group-label" style={{paddingTop: 12}}>Your role</FormLabel>
                    <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        defaultValue="player"
                        name="radio-buttons-group"
                        row
                        onChange={(e) => setRole(e.currentTarget.value)}
                        value={role}
                    >
                        <FormControlLabel value="player" control={<Radio/>} label="Player"/>
                        <FormControlLabel value="spectator" control={<Radio/>} label="Spectator"/>
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'space-between', margin: '4px 12px'}}>
                {
                    !forcedEdit ?
                        <Button onClick={() => closeDialog()} variant={"outlined"}>Close</Button>
                        :
                        null
                }
                <Button onClick={() => closeDialog()} variant={"contained"} style={{width: 120}}>Update</Button>
            </DialogActions>
        </Dialog>
    )
};

export default ProfileDialog;