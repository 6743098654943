import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import moment from 'moment/moment';
import { goldColor } from '../home';

const CommentsDialog = ({open, handleClose, handleSave, task, newComment, setNewComment, remoteComments}) => {
    if (!task) return null;

    const commentsArray = !!remoteComments ? Object.values(remoteComments) : [];
    const sortedComments = [...commentsArray].sort((a, b) => b.createdDt - a.createdDt);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
            <DialogTitle>Task: {task.title}</DialogTitle>
            <DialogContent>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 20}}>
                    <div style={{display: "flex", flexDirection: "row", columnGap: 20, alignItems: 'flex-end', marginBottom: 20}}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="new-comment"
                            label="Write new comment here..."
                            type="text"
                            fullWidth
                            variant="standard"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            autoComplete='off'
                        />
                        <Button variant='contained' onClick={handleSave} disabled={!newComment} style={{width: 250, height: 40}}>Save comment</Button>
                    </div>
                    <Typography variant="h5" color={goldColor}>Comments</Typography>
                    <Table sx={{ minWidth: 450 }} size="small" aria-label="a dense table">
                        <TableBody>
                            {
                                sortedComments?.map((comment) => (
                                    <TableRow key={comment?.id}>
                                        <TableCell style={{width: 120}}>{moment(comment?.createdDt).format('DD.MM.YY  HH:mm')}</TableCell>
                                        <TableCell>{comment.text}</TableCell>
                                    </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CommentsDialog;