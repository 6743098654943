import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';
import { sliderMarks } from './GuidedTourDialog';
import { Typography } from '@mui/material';

const CreateTaskDialog = ({open, handleClose, task, handleCreate, setEditedtask}) => {
    if (!task) return null;

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
            <DialogTitle>Create task</DialogTitle>
            <DialogContent>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 20}}>
                    <Typography variant="body2" alignSelf={"center"}>Importance</Typography>
                    <Slider
                        aria-label="Importance"
                        defaultValue={5}
                        valueLabelDisplay="auto"
                        step={1}
                        marks={sliderMarks}
                        min={1}
                        max={10}
                        value={task?.importance}
                        onChange={(e) => setEditedtask({...task, importance: e.target.value})}
                        name={task.id}
                        style={{width: 400, display: "flex", alignSelf: "center", marginBottom: 40}}
                    />
                    <Typography variant="body2" alignSelf={"center"}>Urgency</Typography>
                    <Slider
                        aria-label="Urgency"
                        defaultValue={5}
                        valueLabelDisplay="auto"
                        step={1}
                        marks={sliderMarks}
                        min={1}
                        max={10}
                        value={task?.urgency}
                        onChange={(e) => setEditedtask({...task, urgency: e.target.value})}
                        name={task.id}
                        style={{width: 400, display: "flex", alignSelf: "center", marginBottom: 40}}
                    />
                </div>
                <TextField
                    autoFocus
                    autoComplete='off'
                    margin="dense"
                    id="title"
                    label="Task title"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={task.title}
                    onChange={(e) => setEditedtask({...task, title: e.target.value})}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                <Button variant='contained' onClick={handleCreate}>Create task</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateTaskDialog;