import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import { goldColor } from "../home";
import '../focused.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const sliderMarks = [
    { value: 1, label: '1' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
];

const renderTaskRow = (activeStep, task = {}, onTitleChange, onImportanceChange, onUrgencyChange) => {
    if (activeStep === 1) {
        return <TextField
            size="small"
            placeholder={"Write a task title here..."}
            onChange={onTitleChange}
            value={task?.title}
            autoComplete={"off"}
            name={task.id}
        />
    }

    if (activeStep === 2) {
        return (
            <div style={{paddingTop: 20}}>
                <Typography variant={"caption"} style={{display: 'flex', width: 400, justifyContent: "center"}}>{task?.title}</Typography>
                <div style={{ width: 400 }}>
                    <Slider
                        aria-label="Importance"
                        defaultValue={5}
                        valueLabelDisplay="auto"
                        step={1}
                        marks={sliderMarks}
                        min={1}
                        max={10}
                        value={task?.importance}
                        onChange={onImportanceChange}
                        name={task.id}
                    />
                </div>
            </div>
        )
    }

    if (activeStep === 3) {
        return (
            <div style={{paddingTop: 20}}>
                <Typography variant="caption" style={{display: 'flex', width: 400, justifyContent: "center"}}>{task?.title}</Typography>
                <div style={{ width: 400 }}>
                    <Slider
                        aria-label="Urgency"
                        defaultValue={5}
                        valueLabelDisplay="auto"
                        step={1}
                        marks={sliderMarks}
                        min={1}
                        max={10}
                        value={task?.urgency}
                        onChange={onUrgencyChange}
                        name={task.id}
                    />
                </div>
            </div>
        )
    }
    return <Typography variant="caption" >{task?.title}</Typography>;
};

const ResultsBlock = ({tasks, title, subtitle}) => {
    return (
        <div style={{paddingTop: 60, maxWidth: 500}}>
            <Typography variant={"h5"} style={{paddingBottom: 8}} color={goldColor}>{title}</Typography>
            <Typography variant={"subtitle2"} style={{paddingBottom: 16}}>{subtitle}</Typography>
            {
                tasks?.length > 0 ?
                    tasks.map((task, index) => <Typography variant={"h6"}>{index + 1}. {task.title}</Typography>)
                    : <Typography variant={"h6"}>No tasks</Typography>
            }
        </div>
    )
}

const GuidedTourDialog = ({open, handleClose, tasks, setTasks, activeStep, remoteTasks, handleAddTasksToDb, handleUpdateTasksDb, handleNextClick}) => {

    const tasksArray = !!tasks ? Object.values(tasks) : [];
    
    // These could be swapped to tasks?.[e.target.name] I think?
    const handleTaskTitleChange = (e) => {
        let newValue = e.target.value;
        const copyOfTasks = [...tasksArray];
        const existingTaskIndex = copyOfTasks.findIndex(task => `${task.id}` === e.target.name);
        const existingTask = copyOfTasks[existingTaskIndex] || {};
        const titleChanged = existingTask.title !== newValue;

        if (titleChanged) {
            copyOfTasks[existingTaskIndex].title = newValue;
            setTasks(copyOfTasks);
        }
    };

    const handleTaskImportanceChange = (e) => {
        let newValue = e.target.value;
        const copyOfTasks = [...tasksArray];
        const existingTaskIndex = copyOfTasks.findIndex(task => task.id === e.target.name);
        const existingTask = copyOfTasks[existingTaskIndex] || {};
        const importanceChanged = existingTask.importance !== newValue;

        if (importanceChanged) {
            copyOfTasks[existingTaskIndex].importance = newValue;
            setTasks(copyOfTasks);
        }
    };

    const handleTaskUrgencyChange = (e) => {
        let newValue = e.target.value;
        const copyOfTasks = [...tasksArray];
        const existingTaskIndex = copyOfTasks.findIndex(task => task.id === e.target.name);
        const existingTask = copyOfTasks[existingTaskIndex] || {};
        const urgencyChanged = existingTask.urgency !== newValue;

        if (urgencyChanged) {
            copyOfTasks[existingTaskIndex].urgency = newValue;
            setTasks(copyOfTasks);
        }
    };

    const handleNewTaskClick = () => {
        const copyOfTasks = [...tasksArray];
        const newTask = {id: copyOfTasks.length, title: "", urgency: 5, importance: 5, createdTs: Date.now(), updatedTs: Date.now(), status: "active"};
        copyOfTasks.push(newTask);
        setTasks(copyOfTasks);
    }

    const executeTasks = (tasksArray?.filter(task => task.urgency > 5 && task.importance > 5) || [])
        .sort((a, b) => b.importance * b.urgency - a.importance * a.urgency);
    const scheduleTasks = (tasksArray?.filter(task => task.urgency <= 5 && task.importance > 5) || [])
        .sort((a, b) => b.importance * b.urgency - a.importance * a.urgency);
    const delegateTasks = (tasksArray?.filter(task => task.urgency > 5 && task.importance <= 5) || [])
        .sort((a, b) => b.importance * b.urgency - a.importance * a.urgency);
    const eliminateTasks = (tasksArray?.filter(task => task.urgency <= 5 && task.importance <= 5) || [])
        .sort((a, b) => b.importance * b.urgency - a.importance * a.urgency);
    
    const resultsBlocks = [
        {
            title: "Do now",
            subtitle: "Tackle these tasks immediately as they are critical for your projects or life. Make sure you have the time booked in your calendar for those tasks ASAP!",
            tasks: executeTasks
        },
        {
            title: "Plan",
            subtitle: "Schedule a specific time to work on these tasks to ensure progress.",
            tasks: scheduleTasks
        },
        {
            title: "Delegate",
            subtitle: "Delegate these tasks if possible if there's anyone they are urgent for, or set aside specific times to handle them quickly",
            tasks: delegateTasks
        },
        {
            title: "Eliminate",
            subtitle: "Minimize or eliminate these tasks to free up time for more important and urgent tasks",
            tasks: eliminateTasks
        },
    ];

    const urgencyMarks = window.innerWidth > 475 ? [
        {value: 1, label: "I'm not in hurry with this"},
        {value: 10, label: "Need it yesterday"}
    ] : [
        {value: 1, label: "No hurry"},
        {value: 10, label: "ASAP"}
    ];
    
    const importanceMarks = window.innerWidth > 475 ? [
        {value: 1, label: "Has no impact on my goals"},
        {value: 10, label: "Massively impacts my goals"}
    ] : [
        {value: 1, label: "No impact"},
        {value: 10, label: "Big impact"}
    ];

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Getting started
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={"guided-tour-container"}>
            {
                activeStep === 1 && !!tasksArray && !remoteTasks?.[0]?.title ?
                    <>
                        <div>
                            <Typography variant={"h1"} style={{fontSize: "3rem", paddingBottom: 16}} color={goldColor}>Take 20 min to create <strong>focus</strong></Typography>
                            <Typography variant={"h4"} style={{paddingBottom: 8}}>What do you need to do in the next day/week/month?</Typography>
                            <Typography variant={"subtitle1"} style={{paddingBottom: 16}}>Make sure to keep your tasks specific!</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", rowGap: 8}}>
                        { tasksArray?.map(task => renderTaskRow(activeStep, task, handleTaskTitleChange, null, null)) }
                        </div>
                        <div className="continue-button-container">
                        {
                            tasks?.length >= 3 ?
                                <Button variant={"text"} onClick={handleNewTaskClick}>Add another task +</Button>
                                :
                                null
                        }
                        </div>
                        <div className={"start-session-container"}>
                            <Button
                                variant={"contained"}
                                size={"large"}
                                onClick={handleAddTasksToDb}
                            >
                                Continue
                            </Button>
                        </div>
                    </> : null
                }
                {
                    activeStep === 2 ?
                        <>
                            <Typography variant={"h1"} style={{fontSize: "3rem", paddingBottom: 16}} color={goldColor}>How <strong>important</strong> is each task?</Typography>
                            <Typography variant={"h5"} style={{paddingBottom: 8}}>"Importance" helps pinpoint tasks that significantly contribute to your long-term goals and achievements.</Typography>
                            <div style={{paddingTop: 20, paddingBottom: 40, width: 250}}>
                                <Slider
                                    defaultValue={5}
                                    valueLabelDisplay="auto"
                                    step={1}
                                    marks={importanceMarks}
                                    min={1}
                                    max={10}
                                    disabled
                                />
                            </div>
                            <div style={{display: "flex", flexDirection: "column", rowGap: 8}}>
                                { tasks.map(task => renderTaskRow(activeStep, task, null, handleTaskImportanceChange, null)) }
                            </div>
                            <div className={"start-session-container"}>
                                <Button
                                    variant={"contained"}
                                    size={"large"}
                                    onClick={handleUpdateTasksDb}
                                >
                                    Continue
                                </Button>
                            </div>
                        </> : null
                }
                {
                    activeStep === 3 ?
                        <>
                            <Typography variant={"h1"} style={{fontSize: "3rem", paddingBottom: 16}} color={goldColor}>How <strong>urgent</strong> is each task?</Typography>
                            <Typography variant={"h5"} style={{paddingBottom: 8}}>"Urgency" helps identify tasks that demand immediate attention due to looming deadlines or pressing circumstances</Typography>
                            <div style={{paddingTop: 20, paddingBottom: 40, width: 250}}>
                                <Slider
                                    defaultValue={5}
                                    valueLabelDisplay="auto"
                                    step={1}
                                    marks={urgencyMarks}
                                    min={1}
                                    max={10}
                                    disabled
                                />
                            </div>
                            <div style={{display: "flex", flexDirection: "column", rowGap: 8}}>
                                { tasks.map(task => renderTaskRow(activeStep, task, null, null, handleTaskUrgencyChange)) }
                            </div>
                            <div className={"start-session-container"}>
                                <Button
                                    variant={"contained"}
                                    size={"large"}
                                    onClick={handleUpdateTasksDb}
                                >
                                    I'm done
                                </Button>
                            </div>
                        </> : null
                }
                {
                    activeStep === 4 ?
                        <div style={{paddingBottom: 100}}>
                            <Typography variant={"h1"} style={{fontSize: "3rem"}} color={goldColor}>Your action plan</Typography>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                { (resultsBlocks || []).map(block => <ResultsBlock {...block}/>) }
                            </div>
                            <Button
                                    variant={"contained"}
                                    size={"large"}
                                    onClick={handleNextClick}
                                >
                                    Go to dashboard
                                </Button>
                        </div> : null
                }
            </div>
        </Dialog>
    );
}

export default GuidedTourDialog;