import {useEffect, useState} from "react";
import './planningPokerSession.css';
import {useParams} from "react-router-dom";
import {ref, onValue, update, set} from "firebase/database";
import {db} from "../index";
import {Button, IconButton, Typography} from "@mui/material";
import {getFromLocalStorage, setToLocalStorage} from "../utils/localStorage";
import {
    findSession,
    findUser,
    pushUserToExistingSessionIfNotAlreadyThere, showVotingRoundVotes, startNewVotingRound,
    updateUserLastActive,
    updateUserNameAndRole
} from "../utils/database";
import ProfileDialog from "../Components/ProfileDialog";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {goldColor} from "./home";
import moment from "moment";
import short from "short-uuid";

const votingOptions = [
    {value: 0, label: "?"},
    {value: 0.1, label: "1h"},
    {value: 0.2, label: "2h"},
    {value: 0.5, label: "4h"},
    {value: 1, label: "1d"},
    {value: 2, label: "2d"},
    {value: 3, label: "3d"},
    {value: 4, label: "4d"},
    {value: 5, label: "5d"},
    {value: 10, label: "2w"},
    {value: 15, label: "3w"},
    {value: 20, label: "4w"},
];

//TODO: Add settings for session. Including the votingOptions configuration.
//TODO: Spectator role
const PlanningPokerSession = () => {
    const [userIdLocal, setUserIdLocal] = useState(getFromLocalStorage('userId'));
    const [userNameLocal, setUserNameLocal] = useState(getFromLocalStorage('username'));
    const [userRoleLocal, setUserRoleLocal] = useState(getFromLocalStorage('userrole'));
    const [activeRoundId, setActiveRoundId] = useState(0);
    const [activeVotingRound, setActiveVotingRound] = useState(null);
    const [userObjects, setUserObjects] = useState([]);
    const [sessionName, setSessionName] = useState(null);
    const [sessionAuthorUserId, setSessionAuthorUserId] = useState('');
    const [profileDialogOpen, setProfileDialogOpen] = useState(false);
    const showVotes = activeVotingRound?.showVotes || false;
    const activeRoundStartTs = activeVotingRound?.startTs || 0;
    const [forcedEdit, setForcedEdit] = useState(false);
    const urlParams = useParams();
    const sessionIdFromUrl = urlParams?.sessionId;
    const sessionsDbRef = ref(db, `sessions/${sessionIdFromUrl}`);
    const currentUserObject = userObjects?.find(user => user?.userId === userIdLocal);
    const [results, setResults] = useState(null);

    useEffect(() => {
        document.title = "Planning Poker Session"
     }, []);

    const checkIfUserExistsAndCreateIfNot = async () => {
        let userId = userIdLocal;
        let userObject = currentUserObject;

        if (!userIdLocal) {
            const uniqueUserId = short.generate();
            setToLocalStorage('userId', uniqueUserId);
            userId = uniqueUserId;
            setUserIdLocal(userId);
        }
        if (!userObject) {
            userObject = await findUser(userId);
        }

        if (!userObject?.userId) {
            await set(ref(db, 'users/' + userId), {
                userId: userId,
                username: 'Anonymous',
                role: 'player',
            });
        }
    };

    const handleShowVotes = () => {
        const filteredVotes = Object.values(activeVotingRound?.votes)?.map(it => it.value)?.filter(it => it);
        const validVotesCount = filteredVotes?.length;
        let total = 0;
        let average = 0;

        filteredVotes.forEach(vote => {
            total += vote;
        });

        if (validVotesCount > 0 && total > 0) {
            average = Math.round(total / validVotesCount);
        }
        setResults(average);
        showVotingRoundVotes(sessionIdFromUrl, activeRoundId);
    };

    const handleResetVotes = () => {
        startNewVotingRound(sessionIdFromUrl, Date.now());
        setResults(null);
    };

    const handleUserProfileUpdate = () => {
        const name = getFromLocalStorage('username');
        const role = getFromLocalStorage('userrole');
        setUserNameLocal(name); //TODO: This doesn't seem optimal.
        setUserRoleLocal(role); //TODO: This doesn't seem optimal.
        setProfileDialogOpen(false);
        setForcedEdit(false);
        updateUserNameAndRole(userIdLocal, name, role, sessionIdFromUrl);
    };

    const updateUserObjects = async (newUsers) => {
        const newObjects = [];
        await Promise.all(newUsers?.map(async it => {
            const userFromDb = await findUser(it);
            newObjects.push(userFromDb);
        }));
        setUserObjects(newObjects);
    };

    const handleNewJoiner = async () => {
        const sessionInDb = await findSession(sessionIdFromUrl);
        if (sessionIdFromUrl && sessionInDb?.sessionId) {
            await checkIfUserExistsAndCreateIfNot();
            await pushUserToExistingSessionIfNotAlreadyThere(sessionIdFromUrl, getFromLocalStorage('userId'));
        }
    }

    const handleUserVote = async (value) => {
        const now = Date.now();
        const valueAsNumber = Number(value);

        if (!!valueAsNumber || valueAsNumber === 0) {
            await update(ref(db, 'sessions/' + sessionIdFromUrl + '/votingRounds/' + activeRoundId + '/votes/' + userIdLocal), {
                user: userIdLocal,
                createdTs: now,
                value: valueAsNumber
            });
            updateUserLastActive(userIdLocal, now);
        }
    }

    // Get sessions with clean up
    useEffect(() => {
        return onValue(sessionsDbRef, (snapshot) => {
            const data = snapshot.val();
            const rawUsers = Object.values(data?.users)?.map(it => it?.userId)?.filter(it => it) || [];
            const rawActiveVotingRound = data?.votingRounds?.[data.activeRoundId];
            if (rawUsers.length) {
                updateUserObjects(rawUsers);
            }
            if (data?.name && sessionName !== data?.name) {
                setSessionName(data?.name);
            }
            if (data?.sessionAuthorUserId && sessionAuthorUserId !== data?.sessionAuthorUserId) {
                setSessionAuthorUserId(data?.sessionAuthorUserId);
            }
            if (data?.activeRoundId && activeRoundId !== data?.activeRoundId) {
                setActiveRoundId(data.activeRoundId);
            }
            if (rawActiveVotingRound) {
                setActiveVotingRound(rawActiveVotingRound);
            }
        });
    }, [sessionName, sessionIdFromUrl, activeRoundId]);

    // Force the Anonymous users to set a username
    useEffect(() => {
        if (userObjects?.length > 0 && currentUserObject?.username === 'Anonymous') {
            setProfileDialogOpen(true);
            setForcedEdit(true);
        }
    }, [userObjects?.length, currentUserObject?.username]);

    // Handle joining straight from invitation link
    useEffect(() => {
        return () => handleNewJoiner();
    }, [sessionIdFromUrl]);

    if (!sessionIdFromUrl) {
        return null;
    }

    return (
        <div className={"poker-session-container"}>
            <div className={"poker-session-header-container"}>
                <Typography variant={"h5"}>{currentUserObject?.username}</Typography>
                <IconButton onClick={() => {
                        setForcedEdit(false);
                        setProfileDialogOpen(true);
                    }}
                >
                    <SettingsOutlinedIcon fontSize={"small"}/>
                </IconButton>
            </div>
            <Typography variant={"h4"}>{sessionName}</Typography>
            <Typography variant={"body1"}>Share session: <a
                href={window.location.href}
                target={"_blank"}
                rel={"noopener noreferrer"}
                style={{color: goldColor}}
                >
                    {sessionIdFromUrl}
                </a>
                <IconButton onClick={() => navigator.clipboard?.writeText(window.location.href)}>
                    <ContentCopyOutlinedIcon fontSize={"small"}/>
                </IconButton>
            </Typography>
            <div className={"poker-session-voting-buttons-container"}>
                <div className={"poker-session-voting-buttons-wrapper"}>
                    {
                        votingOptions?.map(option => (
                            <Button
                                value={option.value}
                                variant={"contained"}
                                color={"primary"}
                                onClick={(e) => handleUserVote(e?.currentTarget?.value || 0)}
                                style={{margin: "6px 8px"}}
                                key={`votingOption-${option.value}`}
                            >
                                {option.label}
                            </Button>
                        ))
                    }
                </div>
            </div>
            <div style={{display: "flex", flexDirection: 'row', width: "100vw", justifyContent: "center", paddingTop: 12}}>
                { activeRoundStartTs > 0 ?
                    <Typography
                        variant={"body1"}
                    >
                        Voting round started: {moment(activeRoundStartTs).startOf('hour').fromNow()}
                    </Typography> :
                    null
                }
            </div>
            {
                sessionAuthorUserId === userIdLocal ?
                    <div className={"poker-session-admin-buttons-container"}>
                        <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={handleResetVotes}
                            key={"reset-votes-button"}
                            style={{width: 150, marginRight: 20}}
                        >
                            reset
                        </Button>
                        <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={handleShowVotes}
                            key={"show-votes-button"}
                            style={{width: 150}}
                        >
                            show votes
                        </Button>
                    </div>
                    :
                    null
            }
            <div className={"poker-session-voting-container"}>
                <div className={"poker-session-players-container"}>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <Typography variant={"body1"}><b>Players</b></Typography>
                                </th>
                                <th>
                                    <Typography variant={"body1"}><b>Vote</b></Typography>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userObjects?.map(it => (
                                    <tr key={`row-${it?.username}`}>
                                        <td>
                                            <Typography
                                                variant={"body1"}
                                                key={`name-${it?.username}`}
                                            >
                                                {it?.username}
                                            </Typography>
                                        </td>
                                        <td>
                                            {
                                                showVotes ? showVotes : it.userId !== userIdLocal ?
                                                    <span style={{
                                                        width: 50,
                                                        height: 20,
                                                        backgroundColor: "black",
                                                        position: 'absolute'
                                                    }} />
                                                    :
                                                    null
                                            }
                                            {activeVotingRound?.votes?.[it?.userId]?.value || 0}
                                        </td>
                                    </tr>
                                    )
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {
                results && showVotes ?
                    <div className={"poker-session-voting-container"}>
                        <div className={"poker-session-players-container"}>
                            <Typography variant={"h5"}>Results</Typography>
                            <div>Average: {results}</div>
                        </div>
                    </div>
                    :
                    null
            }
            <ProfileDialog
                open={profileDialogOpen}
                handleClose={handleUserProfileUpdate}
                forcedEdit={forcedEdit}
                userNameLocal={userNameLocal}
                userRoleLocal={userRoleLocal}
            />
        </div>
    )
}

export default PlanningPokerSession;
